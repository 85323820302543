<template>
  <header v-if="data" class="o-header-default">
    <div class="container">
      <div class="o-header-default__container">
        <div class="o-header-default__left-column">
          <a
            href="/"
            :title="data.site_name ? data.site_name : undefined"
            class="o-header-default__logo-link"
          >
            <img
              :src="`${Logo}`"
              :alt="data.site_name ? data.site_name : undefined"
              :title="data.site_name ? data.site_name : undefined"
              height="31"
              width="200"
              class="o-header-default__logo-image"
            />
          </a>

          <div class="o-header-default__menu">
            <Button
              :selected="websiteConfig.menusState.desktop"
              css-class="button-icon-text"
              icon-pos="right"
              aria-label="Раскрыть меню"
              @click="toggleMenuDesktopHandler"
            >
              <template #icon>
                <i
                  :class="websiteConfig.menusState.desktop ? 'icon-main-close' : 'icon-main-down'"
                ></i>
              </template>

              Все услуги
            </Button>
          </div>
        </div>

        <div class="o-header-default__right-column">
          <div v-if="data.current_city" class="o-header-default__city">
            <Dropbox
              ref="dropboxRef"
              v-model="dropboxState"
              :disabledAutoPosition="disableState"
              v-on-click-outside="onClickOutsideHandler"
            >
              <template #activator="{ toggle, close, opened }">
                <Button
                  css-class="button-icon-text"
                  icon-pos="left"
                  aria-label="Показать города"
                  @click="
                    toggle();
                    toggleBodyScroll();
                    fetchContextCities();
                    closeMenuDesktopHandler();
                  "
                  :selected="opened"
                  :disabled="fetchContextCitiesPending"
                >
                  <template #icon>
                    <i class="icon-info-place o-header-default__city-icon"></i>
                  </template>

                  <span class="o-header-default__city-text">
                    {{ data.current_city }}
                  </span>
                </Button>

                <div
                  class="dropbox-overlay"
                  @click="
                    close();
                    toggleBodyScroll();
                  "
                ></div>
              </template>

              <template #header="{ close }">
                <Button
                  css-class="button-navigation"
                  class="o-header-default__dropbox-back"
                  aria-label="Navigation"
                  aria-disabled="true"
                >
                  <i class="icon-main-prev"></i>
                </Button>

                <div class="dropbox-title">Ваш город</div>

                <Button
                  css-class="button-navigation"
                  aria-label="Закрыть окно"
                  @click="
                    close();
                    toggleBodyScroll();
                  "
                >
                  <i class="icon-main-close"></i>

                  <template #loading>
                    <Loader theme="small" />
                  </template>
                </Button>
              </template>

              <div v-if="fetchContextCitiesPending" class="o-header-default__city-loader">
                <Loader theme="small"></Loader>
              </div>

              <div v-else class="o-header-default__city-cities">
                <a v-for="city in contextCities" :href="city.url" class="link-item">
                  {{ city.title }}
                  <span>{{ city.region_title }}</span>
                </a>
              </div>
            </Dropbox>
          </div>

          <div v-if="data.schedule" class="o-header-default__schedule">
            <i class="icon-info-clock"></i>
            {{ data.schedule }}
          </div>

          <a
            v-if="data.phone"
            class="o-header-default__phone"
            :href="`tel:${usePhone(data.phone).htmlPhone.value}`"
            aria-label="Вызвать мастера"
          >
            <i class="icon-main-phone"></i>
            <span>{{ usePhone(data.phone).normalizedPhone.value }}</span>
          </a>

          <div class="o-header-default__order">
            <Button
              modifier="primary"
              size="small"
              @click="requireForm(formList[EFormNames.FORM_CALLMASTER])"
            >
              Вызвать мастера
            </Button>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';
import Dropbox from '@ice-products-ui/vue-library/Dropbox';
import Loader from '@ice-products-ui/vue-library/Loader';

// Directives
import vOnClickOutside from '@ice-products-ui/vue-library/OnClickOutside';

// Composables
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';
import useDropbox from '~/composables/molecules/useDropbox';
import useScroll from '~/composables/dom/useScroll';
import usePhone from '~/composables/api/usePhone';
import useForms, { EFormNames } from '~/composables/molecules/useForms';
import useMenu from '~/composables/organisms/useMenu';
import useApiBase from '~/composables/api/base/useApiBase';
import { useIceGenericRequest } from '#imports';

// Types
import type { TContextCity } from '~/typings/types/context.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import type { IComponentProps } from '~/typings/types/component.types';

import type { TOHeaderDefault } from '~/components/O/Header/Default/OHeaderDefault.types';
// Schemas

import schema from '~/components/O/Header/Default/OHeaderDefault.schema';
// Static
import Logo from '~/public/images/logo.svg';

defineOptions({
  name: 'OHeaderDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOHeaderDefault>>(), {
  viewName: 'OHeaderDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { baseApiUrl } = useApiBase();
const { requireForm, formList } = useForms();
const { dropboxRef, onClickOutsideHandler, dropboxState, disableState, toggleBodyScroll } =
  useDropboxHeader();
const { contextCities, fetchContextCities, fetchContextCitiesPending } = useContextCities();
const { toggleMenuDesktopHandler, closeMenuDesktopHandler } = useMenu();
useScroll(0, 'header-scrolled', 1000);
const { getGenericPageData, getGenericContextsData } = useIceGenericRequest();

const data = props.data.component;

function useDropboxHeader() {
  const { dropboxState, disableState, toggleBodyScroll } = useDropbox();
  const dropboxRef = ref<InstanceType<typeof Dropbox> | null>(null);
  const onClickOutsideHandler = () => {
    if (!dropboxRef.value) return;
    dropboxRef.value.close();
  };

  return {
    dropboxRef,
    onClickOutsideHandler,
    dropboxState,
    disableState,
    toggleBodyScroll,
  };
}

function useContextCities() {
  const contextCities = ref<TContextCity[]>();
  const fetchContextCitiesPending = ref<boolean>(false);

  const fetchContextCities = async () => {
    if (!getGenericContextsData.value) return;
    if (contextCities.value) return;

    fetchContextCitiesPending.value = true;
    contextCities.value = await $fetch(
      `cities?page_uuid=${getGenericPageData.value.uuid}&contexts=${getGenericContextsData.value}`,
      {
        baseURL: baseApiUrl,
        method: 'GET',
        onResponse() {
          fetchContextCitiesPending.value = false;
        },
      },
    );

    await nextTick(() => {
      if (!dropboxRef.value) return;

      dropboxRef.value.calculate();
    });
  };

  return {
    contextCities,
    fetchContextCitiesPending,
    fetchContextCities,
  };
}

useComponentSchemaValidator(schema, props);
</script>
